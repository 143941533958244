/**
 * @ComponentFor RowLayoutBlockViewModel
 */
import React from 'react';
import { EpiProperty, supportsComponentProp, ComponentType } from '@avensia/scope-episerver';
import RowLayoutBlockType from './RowLayoutBlockViewModel.type';
import BoxLayout, { LayoutType } from 'Shared/BoxLayout';

type RowLayoutContentAreaPropType = {
  elementProps: React.HTMLAttributes<HTMLElement> & {
    children?: React.ReactNode;
  };
  componentProps: {
    component?: ComponentType;
    layout: LayoutType[];
    spacingBetweenBlocks: boolean;
  };
};

const RowLayoutContentArea = ({
  elementProps,
  componentProps: { component: Component = 'div', ...componentProps },
}: RowLayoutContentAreaPropType) => {
  return (
    <Component {...elementProps}>
      <BoxLayout layout={componentProps.layout} spacingBetweenBlocks={componentProps.spacingBetweenBlocks}>
        {elementProps.children}
      </BoxLayout>
    </Component>
  );
};

type PropType = RowLayoutBlockType & {
  component?: ComponentType;
};

export default supportsComponentProp(
  class RowLayoutBlock extends React.Component<PropType> {
    elementComponent: React.StatelessComponent<React.HTMLAttributes<HTMLElement>> = props => (
      <RowLayoutContentArea elementProps={props} componentProps={this.props} />
    );
    render() {
      return <EpiProperty for={this.props.block.items} component={this.elementComponent} />;
    }
  },
);
